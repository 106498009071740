import React from 'react';
import './Form.css';
import axios from 'axios';
import DraftIcon from '@material-ui/icons/Drafts';
import MailIcon from '@material-ui/icons/Mail';
import Modal from '../Modal/Modal';
import Recaptcha from 'react-google-invisible-recaptcha'

const API_PATH = 'https://mailer.stribtech.com/';

const initialState = {
  name: '',
  email: '',
  message: '',
  nameErr:'',
  emailErr:'',
  messageErr:'',
  clicked:false,
  showModal: false,
  sendFormMessage: '',
  mailSent: false,
  token:''
}

let startTime = performance.now();


export default class Form extends React.Component {
  constructor(props){
    super(props);
    this.state=initialState;
    this.onSubmit = this.onSubmit.bind( this );
    this.onResolved = this.onResolved.bind( this );

  }

  change = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  validate =() => {

    let nameErr="";
    let emailErr="";
    let messageErr="";

    if (!this.state.email.includes("@") || !this.state.email.includes(".")) {
      emailErr="Invalid email.";
    }

    if (!this.state.name) {
      nameErr = "The name field cannot be empty.";
    }

    if (!this.state.message) {
      messageErr = "The message field cannot be empty."
    }

    if (emailErr || nameErr || messageErr) {
      this.setState({ emailErr, nameErr, messageErr });

      return false;
    } else {
      this.setState({ clicked:true });
    }


    return true;
  }

  clearForm = () => {
    this.setState(initialState);
  }

  onSubmit(e) {
    e.preventDefault();
    startTime = performance.now();
    this.recaptcha.execute();
  };

  onResolved(){
    const duration = performance.now() - startTime;
    console.log(`Captcha resolved in ${duration}ms`);
    this.setState({
      token: this.recaptcha.getResponse()
    });

    const isValid = this.validate();

    if (isValid) {

      this.setState({
        ...this.state,
        clicked:true
      });


      axios({
        method: 'post',
        // crossDomain: true,
        url: `${API_PATH}`,
        headers: { 'Content-Type': 'application/json' },
        data: this.state
      })
        .then(result => {
          this.setState({
            mailSent: result.data.sent,
            sendFormMessage: result.data.sent ? "Thank you for your message "+this.state.name+". \nWe'll get back to you soon." : "reCAPTCHA validation failed.",
            showModal: true
          })
        })
        .catch(error => this.setState({ error: error.message }));

    } else {
      this.recaptcha.reset();
    }
  }

  render() {
    return(
      <div className="formWrapperDiv">
        <Modal
          onClose = {this.clearForm}
          show ={this.state.showModal}>
          {this.state.sendFormMessage}
        </Modal>
      <form className="formComponent" >

      <div className="txtb">
{/*        <label htmlFor="name">Name:</label>*/}
        <input
          className="inputBox"
          name="name"
          type="text"
          required
          placeholder="Name"
          value={this.state.name}
          onChange={this.change}
        />
      <div className="error">{this.state.nameErr}</div>
    </div>

    <div className="txtb">
{/*        <label htmlFor="email">E-mail: </label>*/}
        <input
        className="inputBox"
        name="email"
        type="email"
        required
        placeholder="E-mail"
        value={this.state.email}
        onChange={this.change}
        />

      <div className="error">{this.state.emailErr}</div>
    </div>

    <div className="txtarea">
        <textarea
        rows={7}
        className="messageField"
        name="message"
        required
        placeholder="Your Message"
        value={this.state.message}
        onChange={this.change}
        />
      <div className="error">{this.state.messageErr}</div>
    </div>

    <Recaptcha
      ref={ ref => this.recaptcha = ref }
      sitekey="6LdcGMsUAAAAAGJJJo0cd73Zy-D-pK8MZ57Uyqm2"
      onResolved={ this.onResolved }

      />


      <button className={this.state.clicked?"submitBtn clicked":"submitBtn"} onClick={e => this.onSubmit(e)}><span className="btnText">Send </span><DraftIcon className="icon icon-draft"/><MailIcon className="icon icon-mail" id="mailIcon" /></button>
      </form>
      </div>
    )
  }
}
