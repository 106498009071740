import React from 'react';
import './Modal.css';

export default class Modal extends React.Component{
  onClose = (e) => {
    this.props.onClose && this.props.onClose(e);
  }
  render(){
    if (!this.props.show) {
      return null;
    }
    return(
      <div className="modal-box">
        {/*whatever is between the <Modal></Modal> gets rendered here*/}
        {this.props.children}

        <div>
          <button className="modal-button" onClick={(e) => { this.onClose(e) }}>OK</button>
        </div>
      </div>
    );
  }
}
