import React from 'react';
import './Footer.css';

export default class Footer extends React.Component{
  render(){
    return(
      <div className="footer">
        <p className="copyright">  Copyright &copy; {(new Date().getFullYear())} StribTech </p>
        {/*<p className="companyInfos">BTW: NL002491585B74</p>*/}
      </div>
    )
  }
}
