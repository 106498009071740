import React from 'react';
import logoTrp from '../css/LogoTransparent.png';
import Form from '../components/Form/Form';
import IconBox from './IconBox/IconBox';
import Footer from './Footer/Footer';
import Fade from 'react-reveal/Fade'


const home = props => {
  return(
    <div className="App" onClick={props.click}>

      <div className="head-banner">
        <div className="head-banner-center">
          <div className="logo-container">
            <img src={logoTrp} alt="logo" />
          </div>
          <div className="text-container">
            <h1 id="welcomeText">StribTech</h1>
            <hr/>
            <h2 id="weDevelopText">We develop software</h2>
          </div>
        </div>
      </div>

      <div className="main-content">
        <Fade bottom>
          <Form />
        </Fade>
      </div>
      <div className="bottom-box">
        <Fade bottom>
          <IconBox />
          <Footer />
        </Fade>
      </div>
    </div>
  );
}

export default home;

// <script type="text/javascript">
//   $(".txtb input").on("focus", function(){
//     $(this).addClass("focus")
//   });
//
//   $(".txtarea textarea").on("focus", function(){
//     $(this).addClass("focus")
//   });
//
//   $(".txtb input").on("blur", function(){
//     if($(this).val() == "")
//     $(this).removeClass("focus");
//   });
//
//   $(".txtarea textarea").on("blur", function(){
//     if($(this).val() == "")
//     $(this).removeClass("focus");
//   });
// </script>

// <form className="contact-form">
//   <h2>Get in touch</h2>
//
//   <div className="txtb">
//     <input type="text" />
//     <span data-placeholder={props.namePlaceHolder}></span>
//   </div>
//
//   <div className="txtb">
//     <input type="text" />
//     <span data-placeholder={props.emailPlaceHolder}></span>
//   </div>
//
//   <div className="txtarea">
//     <textarea name="Message" rows="8" cols="22"></textarea>
//     <span data-placeholder={props.messagePlaceHolder}></span>
//   </div>
//
//   <input type="submit" className="sendBtn" name="" value="Send" />
// </form>
