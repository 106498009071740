import React, { Component } from 'react';
import Home from './components/Home'
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './App.css';

class App extends Component {
state = {
  namePlaceHolder: 'Name',
  emailPlaceHolder: 'E-mail',
  messagePlaceHolder: 'Dear StribTech...'
};


  render() {
    return (

        <BrowserRouter>
          <div>
            <Switch>
              <Route exact path={'/'} component={Home}/>
              <Route component={Home} />
            </Switch>
          </div>
        </BrowserRouter>




    );
  }
}

export default App;

// <Route path={'/about'} component={About} />
// <Route path={'/contact'} component={Contact} />
